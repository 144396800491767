.containerT{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    width: 100%;
    padding: 2%; 
    font-size: 0.7rem;

}


.Droite{
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}



.logoT {
      
    width: 10vw;
    height: 15vh;
  
} 


.Gauche{
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 0.5rem;


}


.Tampon{
    align-self: flex-end;
  }


.pStart{
  align-self: flex-start;
}

  







