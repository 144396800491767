
  
  
 








.Reload{
  cursor: pointer;
}

.NavBar{
  /* box-shadow: -0.5px 1px 5px 1.5px rgba(88, 88, 88, 0.3) inset; */
  background-color:rgb(255, 242, 243);

}


.majTxt{
  font-weight: lighter;
  color: #72959e;
}



.navbar-light .navbar-nav .nav-link {
  color: black;
}

.navbar-light .navbar-brand {
  color: black;
}




