


.ContainerBulle{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.Consultation H1{
    color: #72959e;
    font-size: 6vmin;
}

.ContainerInter{
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

}


.BouttonSuivant{
    display: none;
    
}

.BouttonSuivantActif{
    align-self: flex-end;
}

.BouttonRetour{
    align-self: flex-start;
}





