@import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');



html {
  font-size: 100%;
}

html .Bold{
  font-weight: bold;
}
.App{
  /* font-family: 'PT Sans',cursive ; */
  font-size: 1.3rem;
  background-color: rgb(252, 245, 241);

  

}

.MargeHori{
  width: 100%;
  height: 10vh;
}

.MargeHoriPetite{
  width: 100%;
  height: 5vh;

}

.MidContainerHome{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Large{
  font-style: oblique;
  font-weight: bold;
}

@media screen and (min-width: 500px) {
  .App {
   
    /* width: 100vw;
    height: 100vh; */
    /* margin: 0; */
    
   
    

    width: 100%;
    
    /* padding: 0.5%; */
    height: auto;
  }

  .TextStart{
    text-align: center;
    width: 70%;
    font-size: 1.4rem;
    min-height: 70vh;
    font-weight: 600;

}



.TextStart[animation="1"] {
    animation: heartbeat 1.5s ease-in-out both;
}


  .QuestionMetierStyle{
    width: 50vw;
    min-height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: #72959e 1px solid;
    border-radius: 20px;
    background-color:rgb(114, 149, 158) ;
    color: rgb(252, 245, 241);
    padding: 1%;
    text-align: center;

    

}
.BlocConsultationStyle{
    
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  border: solid 5px #72959e ;
  background-color: #e3edf0 ;
  border-radius: 35px;
  padding: 5%;
  width: 60%;
  min-height: 50vh;
  transition: all 0.5s linear;
  box-shadow:  0 0 0.3em rgb(0, 0, 0);


  /* background-color: rgba(0, 0, 0, 0.3);
  background-size: 100% 50%;
  border-radius: 50px; */

 


  
  
}


.BlocConsultationStyleActiv{
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  border: solid 5px #478997 ;
  background-color: #e3edf0 ;
  color: #e3edf0 ;
  border-radius: 35px;
  padding: 5%;
  width: 60%;
  min-height: 50vh;
  transform: rotateY(160deg); 
  transition: all 0.5s linear;

  /* background-color: rgba(0, 0, 0, 0.3);
  background-size: 100% 50%;
  border-radius: 50px; */




  
  
}

.BulleInfo{
  background-color:  rgb(255, 242, 243);
  border-radius: 20px;
  width: 80%;
  margin-bottom: 1.5vh;
  border: solid 2.5px #72959e ;
  padding: 1%;
  text-align: center;



}

.BlocConsultationStyle[animation="1"] {
  transform: rotateY(360deg); 
  transition: all 1s linear;}



  .Consultation{
    width: 100%;
    min-height: 60vh;
    border-radius: 20px;
    border: 10px solid #72959e;
    background-color: #72959e13;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 3%;  
    /* animation: pulsate-bck 5s ease-in-out infinite both; */

  



}

.noneArrow{
  display: none;
}

.Arrow{
  display: none;
 

}

  .Image {
    width: 8vw;
    height: 10vh;
}


  #contactDisplay{
    height: 60vh ;
    width: 100%;
}
.SponsorContainer {
  width: 100vw;
  height: 20vh;
  display: flex;
  justify-content: space-around;
  background-color: white;
  align-items: center;
}

.Header{
  height: 30vh;
}

.HeaderMenu{
  height: 10vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

}

.QuestionConsulationStyle{
    width: 50vw;
    min-height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: #72959e 1px solid;
    border-radius: 20px;
    background-color:#72959e ;
    color: rgb(252, 245, 241);
    padding: 1%;
    

}

.QuestionConsulationStyle Button{
  width: 40vw;
}





.HeaderTop {

  width: 100vw; 
  height: 20vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
  

}
.Banniere{
  width: 65vw;
  height: 15vh;
  cursor: pointer;
  
}

.Verrou{
  border: 3px solid red;
  border-radius: 20px;
}

.VerrouMin{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.75% 0.5% 0% 0.5%; 

  align-items: center;
  border: 3px solid red;
  border-radius: 20px;
  
  

}





.logoH {
  
  width: 27vw;
  height: 21.5vh;
  

}



.Menu {
  border: 1px solid black;
  width: 5vw;
  height: 10vh;
}





  .App::-webkit-scrollbar { width: 0 !important };

  /* .FooterTest{
    position: absolute;
    bottom: 0;
    border: 4px solid green;
  }
   */
}





























@media screen and (max-width: 500px) {
  .App {
    text-align: center;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin: 0; */
    
 
    /* padding: 1%; */
  }

  .BorderApp{
    display: none;
}


.TextStart{
  text-align: center;
  width: 90%;
  font-size: 1.4rem;
  min-height: 70vh;
  font-weight: 600;

}



.TextStart[animation="1"] {
  animation: heartbeat 1.5s ease-in-out both;
}

.Mid{
  width: 100%;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Core{
  width: 100%;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  
  align-items: center;
  
}

.logoH{
  width: 40vw;
  height: 20vh;
}

.Banniere{
  display: none;
}

.Header{
  height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.HeaderTop {

  width: 100vw; 
  height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  

}

.HeaderMenu{
  height: 10vh;
  width: 100%;
  /* display: flex;
  flex-direction: row;
  justify-content: center; */
  

}

.QuestionMetierStyle{
  width: 100%;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: #72959e 1px solid;
  border-radius: 20px;
  background-color:#72959e ;
  color: rgb(252, 245, 241);
  padding: 1%;
  text-align: center;

  

}

.ContainerQuestion{
  text-align: center;
}

.Image {
  width: 19vw;
  height: 15vh;
}
.SponsorContainer {
  width: 100vw;
  height: 20vh;
  display: flex;
  flex-direction: row;
  background-color: white;
  justify-content: space-between;
  align-items: center;
}

.QuestionConsulationStyle{
  width: 100%;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: #72959e 1px solid;
  border-radius: 20px;
  background-color:#72959e ;
  color: rgb(252, 245, 241);
  padding: 1%;
  

}

.QuestionConsulationStyle Button{
  width: 60vw;
}





  .MargeHoriPetite{
    display: none;
  
  }


  .BlocConsultationStyle{
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    border: solid 3px #72959e ;
    background-color: #e3edf0 ;
    border-radius: 35px;
    padding: 5%;
    width: 100%;
    min-height: 50vh;
    transition: all 0.5s linear;
    font-size: 4vmin;


    /* background-color: rgba(0, 0, 0, 0.3);
    background-size: 100% 50%;
    border-radius: 50px; */

   


    
    
}
.BlocConsultationStyleActiv{
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  border: solid 3px #478997 ;
  background-color: #e3edf0 ;
  color: #e3edf0 ;
  border-radius: 35px;
  padding: 5%;
  width: 100%;
  min-height: 50vh;
  transform: rotateY(160deg); 
  transition: all 0.5s linear;
  opacity: 0;

  /* background-color: rgba(0, 0, 0, 0.3);
  background-size: 100% 50%;
  border-radius: 50px; */




  
  
}

.Consultation{
  width: 100%;
  min-height: 95vh;
  border-radius: 20px;
  border: 3px solid #72959e;
  background-color: #72959e13;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 3%;  
  /* animation: pulsate-bck 5s ease-in-out infinite both; */





}

.Arrow{
  height: 5vh;
  position: fixed;
  bottom: 10%;
 right: 0;
 

}

.noneArrow{
display: none;
}


.BulleInfo{
  background-color:  rgb(255, 242, 243);
  border-radius: 20px;
  width: 95%;
  margin-bottom: 1.5vh;
  border: solid 2.5px #72959e ;
  text-align: center;
  font-size: 3.5vmin;



}
}

































a{
  
  text-decoration: none;



}




.navbar-brand{
  font-weight: bold;
  font-size: large;

}

.navbar{
  font-weight: bold;
  font-size: large;

}

.iconPDFNew{
  height: 6.5vh;
  outline: none;
  transition: 0.8s;
}

.iconPDFNewHover{
  height: 8vh;
  outline: none;
  transition: 0.5s;

}



.Red{
  color: red;
}






