.logo {
      
    width: 7vw;
    height: 10vh;
  
  }

  .containerR{
    
    height:200vh;
    width: 100%; 
    font-size: 0.55rem;

    }


    .Bloc1Resume{
      display: flex; 
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  width: 100%;
  padding: 2%;
    }



.Bloc2Resume{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  width: 100%;
  padding: 2%;


}