
@import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');

.Mid{
    width: 100%;
    min-height: 70vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.BorderApp{
    width: 15%;
    height: 100%;
}


.Core{
    width: 100%;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}






.ContainerTextes{
    width: 100%;
    min-height : 10vh;
    margin-bottom: 2vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


