

.ButtonRetour{
  align-self: flex-end;
}


/* .QuestionMetierStyle[animation="0"] {
    animation: pulsate-bck 0.8s ease-in-out infinite both;
}



@keyframes pulsate-bck {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.98);
    }
    100% {
      transform: scale(1);
    }
  } */





